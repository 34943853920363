import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';

const App = lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>Loading...</div>}>
    <App />
  </Suspense>
);
